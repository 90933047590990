ol#editor {
    counter-reset: item;
    list-style-type: none;
}
ol#editor li#uml-item {
    color: black;
    display: block;
    padding-inline-start: 1rem;
}
ol#editor li#uml-item:before {
    content: counter(item) "\00a0 \00a0 \00a0 \00a0 \00a0";
    counter-increment: item;
    display: inline-block;
    width: 30px;
}

span#uml-item:before {
    content: ' ';
    display: inline-block;
    width: 30px;
}

samp {
    cursor: not-allowed;
}

var {
    cursor: text;
}

.new-element {
    background-color: #dce7e6;
}

.inner .first {
    margin-left: 20px;
}

.inner .inner .first {
    margin-left: 40px;
}

.inner-name {
    margin-left: 87px;
}

.inner .inner-name {
    margin-left: 105px;
}

.closing-bracket {
    padding-left: 65px;
}

.inner .closing-bracket {
    padding-left: 85px;
}

.delete-item {
    color: red;
    text-decoration: line-through;
}

.element-name {
    color: #6b5b95;
}

.connection-ids {
    color: #6b5b95;
}

.static-connectors {
    color: #d64161;
}

.elements-ids {
    color: #feb236;
    cursor: copy;
    position: relative;
}

.elements-ids:before {
    content: '';
    display: none;
    position: absolute;
    z-index: 9999;
    top: -14px;
    left: 132px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0,0,0,.60);
}

.elements-ids:after {
    content: 'Copy to Clipboard';
    display: none;
    position: absolute;
    z-index: 9999;
    top: -50px;
    left: 80px;
    width: 114px;
    height: 36px;

    color: #fff;
    font-size: 10px;
    line-height: 36px;
    text-align: center;

    background: rgba(0,0,0,.60);
    border-radius: 3px;
}

.elements-ids:hover:before, .elements-ids:hover:after {
    display: block;
}

.elements-ids:active, .elements-ids:focus {
    outline: none;
}

.elements-ids:active:after, .elements-ids:focus:after {
    content: 'Copied!';
}


.aux-text {
    color: #878f99;
}

.element-brackets {
    color: #034f84;
}

.revert {
    color: #d64161;
    cursor: pointer;
}

.move-inside-btn {
    margin-left: 30px;
    cursor: pointer;
    font-size: 1.5rem;
}

.react-transform-wrapper.transform-component-module_wrapper__SPB86 {
    /* height: 100%; */
    width: 100%;
    /* max-height: 100%; */
    max-width: 100%;
    flex-grow: 1;
}

#image-viewer {
    min-height: 10em;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

#image-viewer button {
  border: none;
  color: #737373;
  background-color: white;
  padding: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
    #image-viewer {
        margin-bottom: 1em;
    }
}
